<template>
    <div>
        <errors-bag-list/>

        <b-card no-body class="mb-1 py-3 text-center">
            <b-container fluid class="container-margin text-left" v-if="act">
                <b-row class="my-1 row-margin">
                    <b-col md="12">
                        <label for="actualShift"><b>ID: </b> {{ act.id || null }}</label>
                        <actual-shift-picker id="actualShift" v-model="act.actualShift" :disabled="disabled"/>
                    </b-col>
                </b-row>

                <b-row class="my-1 row-margin">
                    <b-col md="6">
                        <label for="department"><b>Подразделение</b></label>
                        <department-picker id="department" v-model="act.department" :disabled="disabled"/>
                    </b-col>
                    <b-col md="6">
                        <label for="time"><b>Время</b></label>
                        <date-time-picker
                            input-size="sm"
                            id="time"
                            label="Время"
                            :no-label="true"
                            :only-time="true"
                            :no-button-now="true"
                            :no-clear-button="true"
                            format="HH:mm"
                            formatted="HH:mm"
                            v-model="act.time"
                            :disabled="disabled"
                        />
                    </b-col>
                </b-row>

                <b-row class="my-1 row-margin">
                    <b-col md="6">
                        <label for="score"><b>Баллы первой проверки</b></label>
                        <b-form-input id="score" v-model="act.score" :formatter="scoreFormatter" :disabled="disabled"/>
                    </b-col>
                    <b-col md="6">
                        <label><b>Балл повторной проверки</b></label>
                        <b-form-input id="additional-score" v-model="act.additional_score" :formatter="scoreFormatter"
                                      :disabled="disabled"/>
                    </b-col>
                </b-row>

                <b-row class="my-1 row-margin">
                    <b-col md="12">
                        <label for="comment"><b>Комментарий</b></label>
                        <b-form-textarea id="comment" v-model="act.comment" rows="3" :disabled="disabled"/>
                    </b-col>
                </b-row>
                <template v-if="created && act.responsible.length > 0">
                    <b-row class="my-1 row-margin" v-for="(id, index) in act.responsible_ids">
                        <b-col md="2">
                            <label><b>Провел {{ index + 1 }} проверку</b></label>
                        </b-col>
                        <b-col md="10">
                            <b-form-input id="createdBy"
                                          v-model="act.responsible.find(el => el.id === id).fullname || ''"
                                          :disabled="true"/>
                        </b-col>
                    </b-row>
                </template>
                <b-row class="my-1 row-margin">
                    <b-col md="2">
                        <label for="createdAt"><b>Время создания</b></label>
                        <b-form-input v-model="act.created_at_with_tz" :disabled="true"/>
                    </b-col>
                </b-row>
                <b-row class="my-1 row-margin" v-if="created">
                    <b-col md="10">
                        <label for="updatedBy"><b>Последнее изменение</b></label>
                        <b-form-input id="updatedBy" v-model="act.updatedBy.fullname" :disabled="true"/>
                    </b-col>
                    <b-col md="2">
                        <label for="udpatedAt"><b>Время изменения</b></label>
                        <b-form-input id="udpatedAt" v-model="act.updated_at_with_tz" :disabled="true"/>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>

        <control-panel>
            <b-button variant="warning" @click="$router.go(-1)" :disabled="disabled">Отмена</b-button>
            <b-button variant="danger" @click="deleteAct" :disabled="disabled" v-if="created">Удалить</b-button>
            <b-button variant="success" @click="saveAct" :disabled="disabled">Сохранить</b-button>
        </control-panel>
    </div>
</template>

<script>
import moment from "moment"

import DateTimePicker from "vue-ctk-date-time-picker"

import ControlPanel      from "@components/_common/ControlPanel"
import DepartmentPicker  from "@components/_common/DepartmentPicker"
import ErrorsBagList     from "@components/_common/ErrorsBagList"
import ActualShiftPicker from "@components/_common/ActualShiftPicker.vue"

import ErrorsBag                          from "@utils/errorsBag"
import {SANITARY_INSPECTION_ACT_ENDPOINT} from "@utils/endpoints"

export default {
    name: "Form",
    props: {
        id: {
            type: Number,
            default: null
        }
    },
    components: {
        ControlPanel,
        DepartmentPicker,
        ErrorsBagList,
        DateTimePicker,
        ActualShiftPicker,
    },
    computed: {
        created() {
            return this.act && this.act.id
        },
        disabled() {
            return this.saving || this.deleting || this.loading
        },
    },
    data() {
        return {
            act: null,
            saving: false,
            deleting: false,
            loading: false,
            ErrorsBag,
        }
    },
    methods: {
        async saveAct() {
            ErrorsBag.discard()

            if (this.act.score === null || this.act.score === '' || this.act.additional_score === null || this.act.additional_score === '') {
                if (this.act.score === null || this.act.score === '') {
                    this.$toast.error('Вы не ввели баллы первой проверки')
                }

                if (this.act.additional_score === null || this.act.additional_score === '') {
                    this.$toast.error('Вы не ввели баллы повторной проверки')
                }

                return;
            }

            this.saving = true

            const data = {
                date: this.act && this.act.actualShift && this.act.actualShift.date || null,
                actual_shift_id: this.act && this.act.actualShift && this.act.actualShift.id || null,
                time: this.act && this.act.time || null,
                department_id: this.act && this.act.department && this.act.department.id,
                score: this.act && this.act.score,
                additional_score: this.act && this.act.additional_score,
                comment: this.act && this.act.comment,
            };

            const request = this.act.id
                ? this.$http.put(`${SANITARY_INSPECTION_ACT_ENDPOINT}/${this.act.id}`, data)
                : this.$http.post(SANITARY_INSPECTION_ACT_ENDPOINT, data);

            try {
                await request;
                this.$toast.success("Акт успешно сохранен")
                this.$router.push({name: "SanitaryInspectionActList"})
            } catch (response) {
                ErrorsBag.fill(response.body);
                this.$toast.error("Не удалось сохранить акт")
            } finally {
                this.saving = false
            }
        },
        deleteAct() {
            if (confirm("Вы уверены, что хотите удалить данный акт?")) {
                ErrorsBag.discard()

                this.deleting = true

                this.$http
                    .delete(SANITARY_INSPECTION_ACT_ENDPOINT + "/" + this.id)
                    .then(() => {
                        this.$toast.success("Акт успешно удален")
                        this.$router.push({name: "SanitaryInspectionActList"})
                    })
                    .catch((response) => {
                        ;(response) => ErrorsBag.fill(response.body)
                        this.$toast.error("Не удалось удалить акт")
                    })
                    .finally(() => {
                        this.deleting = false
                    })
            }
        },
        fetchAct() {
            ErrorsBag.discard()

            this.loading = true

            this.$http
                .get(SANITARY_INSPECTION_ACT_ENDPOINT + "/" + this.id, {
                    params: {
                        with: ["actualShift.shift", "department", "createdBy", "updatedBy"],
                        appends: ['created_at_with_tz', 'updated_at_with_tz'],
                    }
                })
                .then((response) => {
                    this.act = response.data.data
                    this.act.time = moment(this.act.time, "HH:mm:ss").format("HH:mm")
                })
                .catch((response) => {
                    ErrorsBag.fill(response.body)
                    this.$toast.error("Не удалось загрузить акт")
                })
                .finally(() => {
                    this.loading = false
                })
        },
        initAct() {
            this.act = {
                id: null,
                actualShift: {id: null},
                time: "00:00",
                department: {id: null},
                score: 0,
                comment: null,
            }
        },
        scoreFormatter(value) {
            if (value !== '' && Number.parseInt(value) < 0) {
                return 0
            }
            if (Number.parseInt(value) > 100) {
                return 100
            }
            return value
        },
    },
    mounted() {
        if (this.id) {
            this.fetchAct()
        } else {
            this.initAct()
        }
    },
}
</script>

<style lang='sass' scoped>
.row-margin
    margin-top: 10px !important

    .container-margin
        margin: 10px 0 !important
</style>
